<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
      >
        <div id="printMe">
          <b-row class="mt-50 mb-3">
            <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">Vardiya Gider Listesi</h4>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-print="'#printMe'"
                    variant="bgInfo"
                    class="float-right"
                    :style="{backgroundColor:$store.getters.cardTitleButton}"
                >
                  <feather-icon
                      icon="PrinterIcon"
                      class="mr-50"
                  />
                  Gider Kayıtlarını Yazdır
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    :to="'/vardiyaOzeti/' + vardiyaID"
                    class="myButton mr-50 border-0 float-right"
                    :style="{backgroundColor:$store.getters.cardTitleButton}"
                >
                  <feather-icon
                      icon="PieChartIcon"
                      class="mr-50"
                  />
                  Vardiya Özeti
                </b-button>
              </b-col>
            </div>
          </b-row>
          <hr>
          <table class="table table-striped mt-1 table-hover table-responsive-sm mt-lg-2 mt-sm-5 mt-5">
            <thead>
            <tr>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                TUTAR
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                AÇIKLAMASI
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                TARİH
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(gider, index) in this.$store.getters
                  .giderKayitlariReturn"
                :key="index"
            >
              <td>{{ gider.tutar }} TL</td>
              <td>{{ gider.aciklama }}</td>
              <td>{{ gider.kayitTarih }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      vardiyaID: this.$route.params.id
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('clearGiderKayitlari')
  },
  created() {
    if (this.vardiyaID != 0) {
      this.$store
          .dispatch('giderKayitlari', {
            vardiyaID: this.vardiyaID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    } else {
      this.spinner = false
      this.$store
          .dispatch('giderKayitlari', {
            vardiyaID: 0,
          })
          .then((res, position = 'bottom-right') => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            this.spinner = false
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    }
  },
  methods: {
  },
}
</script>

<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #285274;
  color: white;
}

[dir] .table thead th {
  background-color: #285274;
  padding: 3px !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.card-body {
  padding: 10px 30px;
}

.top-row-col-4 {
  display: inherit;
  align-items: end;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}
</style>
